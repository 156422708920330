<template>
    <div class="wrap scroll p-mypage-child">
        <div class="container">
            <layout-header title="자녀관리" btn-left="logo" btn-right="close"></layout-header>

            <div class="content">
                <div class="add-button">
                    <img alt="" src="/assets/images/mypage/img_mypage_child.png">
                    <button class="btn-member purple lg" type="button">
                        <router-link :to="{name: 'profileJoin'}">자녀 추가</router-link>
                    </button>
                </div>
                <template v-for="(item, index) in profileAccountList">
                    <div v-bind:key="index" :value="item.coaccountVO.caidx" class="child-info">
                        <strong>자녀 {{ index + 1 }}</strong>
                        <div class="info-list">
                            <dl>
                                <dt>아이디</dt>
                                <dd>
                                    {{ item.coaccountVO.coEmailId }}
                                    <span v-if="item.coaccountVO.authEamil" class="grade">정회원</span>
                                </dd>
                            </dl>
                            <dl>
                                <dt>자녀 이름</dt>
                                <dd>{{ item.coaccountVO.nickName }}</dd>
                            </dl>
                            <dl>
                                <dt>기관</dt>
                                <dd>{{ item.coinfoVO.coName }}</dd>
                            </dl>
                        </div>
                        <router-link
                            :to="{name: 'confirmOut', query: {isParent: 'false', caidx: item.coaccountVO.caidx}}"
                            class="btn-leave btn-member">탈퇴하기
                        </router-link>
                    </div>

                </template>

            </div>
        </div>
    </div>
</template>

<script>
import LayoutHeader from "@/pages/layouts/layoutHeader";

export default {
    name: "profileManage",
    components: {LayoutHeader},
    data() {
        const coEmailId = this.getLoginMainId()

        return {
            coEmailId,
            profileAccountList: Object
        }
    },
    created() {
        this.requireLoginMainWithoutAlert()
        this.getProfile()
    },
    mounted() {


    },
    methods: {

        getProfile() {

            const self = this
			const _ = window._
            this.$eventBus.$emit("startProgress")

            this.axiosCaller.get(self, self.APIs.CO_PROFILE + '/getChild', {
                coEmailId: self.coEmailId
            }, (res) => {

                self.profileAccountList = res.data.profileAccountList
                self.$eventBus.$emit("doneProgress", 500)

            })
        },
    }
}
</script>

<style scoped>

</style>